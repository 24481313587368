import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { APPLICATION_VERSION, isBackendProduction } from './utils/config';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

export const DEFAULT_LANGUAGE_CODE = 'en';

const langDetectorOptions = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage', 'cookie'],
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'languageOnly',
    // 'lng' attribute overrides language detection; to enable language detection back, simply remove 'lng'
    lng: DEFAULT_LANGUAGE_CODE,
    fallbackLng: DEFAULT_LANGUAGE_CODE,
    debug: !isBackendProduction() && process.env.NODE_ENV !== 'test',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: [
      'apps',
      'common',
      'components',
      'dataSources',
      'help',
      'login',
      'menu',
      'notifications',
      'other',
      'overview',
      'settings',
      'templates',
      'utils',
      'workspaces',
      'printers',
      'dialogs',
    ],
    react: {
      //useSuspense: false,
    },
    backend: {
      loadPath: `${
        process.env.PUBLIC_URL
      }/locales/{{lng}}/{{ns}}.json?v=${encodeURIComponent(
        APPLICATION_VERSION,
      )}`,
    },
    detection: langDetectorOptions,
  });

export default i18n;
