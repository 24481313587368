import {
  createReducer,
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducer,
} from 'ducks/utils';
import { combineReducers } from 'redux';
import { types } from './actions';

const printerTools = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.PRINTER_TOOLS),
});
const links = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.LINKS),
});
const ldaVersions = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.LDA_VERSIONS),
});
export default combineReducers({
  printerTools,
  links,
  ldaVersions,
});
