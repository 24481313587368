import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useReduxAction, useReduxSelector } from '../../hooks';
import { getCustomer } from '../../ducks/auth/selectors';
import { PortalMessageType } from '../lda/PortalMessageType';
import {
  getAllLabelSizes,
  getClientId,
  getPortalVersion,
  getPrinters,
  getWorkspaces,
} from '../../services/api';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';
import { useHistory } from 'react-router-dom';
import {
  CONTACT_FIELDS as MS_CONTACT_FIELDS,
  getOneDriveContacts,
} from '../../services/office365';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import tokenStorage from '../../services/tokenStorage';
import { actions as templateActions } from '../../ducks/templates/actions';
import { actions as printDialogActions } from '../../ducks/printDialog/actions';
import { actions as iframeActions } from '../../ducks/iframe/actions';
import { openErrorToast, openToast } from '../../state/Toast';
import { isLdaPostMessageValid, LdaPostMessage } from '../../utils/lda';
import { compressImage } from '../../utils/imageCompress';
import { APPLICATION_VERSION } from '../../utils/config';
import { GOOGLE_CONTACTS_HEADERS } from '../../services/google/googleContacts';
import googleApi from '../../services/google/googleApi';
import {
  buildPermissionsNotGrantedToastOptions,
  PermissionsNotGrantedError,
} from '../../services/permissions';
import { getQuery } from 'ducks/router/selectors';

const MOBILE_CONTACT_HEADERS = [
  'Display name',
  'Given name',
  'Middle name',
  'Family name',
  'Email',
  'Phone',
  'Address - Street',
  'Address - City',
  'Address - Country/Region',
  'Address - Postal Code',
  'Address - State',
];

const MOBILE_CONTACT_EXAMPLES = [
  'Example Name',
  'Given-Example',
  'Middle-Example',
  'Family-Example',
  'example@example.exa',
  '123456789',
  '1 Example Street',
  'Exampleville',
  'Exampleshire',
  'EX1234',
  'Exampia',
];

const MOBILE_CONTACT_COLUMNS = [
  MOBILE_CONTACT_HEADERS,
  MOBILE_CONTACT_EXAMPLES,
];

const DBG = [
  '%c IFRAME ',
  'color:magenta;background-color:brown;border:1px solid black;',
];

export const UPLOAD_DB_MIMETYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.google-apps.spreadsheet',
  'application/vnd.ms-excel',
  'text/csv',
  'text/plain',
  'application/octet-stream',
];

export const DOWNLOAD_DB_MIMETYPES = [...UPLOAD_DB_MIMETYPES];

export const IMAGE_MIMETYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/bmp',
  'image/vnd.adobe.photoshop',
];

export const ALL_MIMETYPES = [...UPLOAD_DB_MIMETYPES, ...IMAGE_MIMETYPES];
export const CLOUD_FILES_FIELDS = {
  name: 'name',
  lastModified: 'dateAdded',
  mimetype: 'mimeType',
  provider: 'dataSource',
  linkedMicrosoftAccount: 'linkedMicrosoftAccount',
};

let cachedFileId: string | null = null;

function handleFiles(callback, action) {
  console.debug(...DBG, 'handleFiles');
  const { loading, data } = window['store'].getState()['dataSources'][action];
  if (!loading) {
    callback(data);
  }
}

export const contactsArrayToDataSourceObject = (contacts): any[] => {
  if (contacts.length === 1)
    contacts[1] = new Array(contacts[0].length).fill(null);
  return contacts.reduce((acc, row, index) => {
    if (index === 0) {
      return row.map((label, value) => {
        return { label, value };
      });
    }
    row.forEach(
      (provisionalValue, i2) => (acc[i2] = { ...acc[i2], provisionalValue }),
    );
    return acc;
  }, {});
};

const handleGetMetadata = (callback) => {
  console.debug(...DBG, 'handleUploadDataSource');
  const { loading, success, error, data } =
    window['store'].getState()['dataSources']['selected'];

  if (!loading || !(success === false && typeof error === 'undefined')) {
    callback(typeof data === 'undefined');
  }
};

const handleCallback = (callback, actionState, action) => {
  const { loading, error, data } =
    window['store'].getState()[actionState][action];
  if (!loading) {
    console.debug(
      '%c IFRAME ',
      'color: magenta;background-color: brown',
      'handleImage',
    );

    if (error) {
      callback(null);
    } else {
      callback(data ?? null);
    }
  }
};

export enum ContactsProvider {
  GOOGLE = 'Google',
  OFFICE365 = 'Office365',
  MOBILE_DEVICE = 'Mobile Device',
}

export type LdaListenerParams = {
  sendPostMessage: (postMessage: LdaPostMessage) => void;
};

export const LDAListener = ({
  sendPostMessage,
  children,
}: React.PropsWithChildren<LdaListenerParams>) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isOneDriveAuthenticated = useIsAuthenticated();
  const msalContext = useMsal();

  const getFileList = useReduxAction(dataSourcesActions.ALL.request);
  const getIframeDataSource = useReduxAction(iframeActions.DATASOURCE.request);
  const getIframeImage = useReduxAction(iframeActions.IMAGE.request);
  const setSelectedDataSource = useReduxAction(
    dataSourcesActions.SELECTED.request,
  );
  const getSelectedTemplateData = useReduxAction(
    templateActions.SELECTED_DATA.request,
  );
  const openPrintDialog = useReduxAction(printDialogActions.OPEN);

  const customer = useReduxSelector(getCustomer);
  const queryParams = useReduxSelector(getQuery);

  const getFileMetaData = useCallback(
    (file, uuid) => {
      console.debug(...DBG, 'getMetaData', file);

      const unsubscribe = window['store'].subscribe(() =>
        handleGetMetadata((data) => {
          sendPostMessage({
            isSohoPortal: true,
            messageType: PortalMessageType.RequestFileMetaDataResponse,
            uuid,
            data,
          });
          unsubscribe();
          // We cleared the cache here to avoid an issue where a user can disconnect file and attempt to upload it
          // again and for refresh data file as well.
          cachedFileId = null;
        }),
      );
      // This caching strategy is used to avoid an issue where we make double request with same file and this
      // caused unexpected app behavior. Also added checks for refresh data sources (SMBUI-1964)
      if (
        cachedFileId !== file.id &&
        file.id !== 'Office365' &&
        file.id !== 'Google' &&
        file.id !== 'Mobile Device'
      ) {
        setSelectedDataSource(file);
      }
      cachedFileId = file.id;
    },
    [sendPostMessage, setSelectedDataSource],
  );

  const handlePostMessage = useCallback(
    async (
      messageType: PortalMessageType,
      uuid: string,
      data: any,
    ): Promise<void> => {
      const sendLdaPostMessage = (
        messageType: PortalMessageType,
        data?: any,
      ) => {
        sendPostMessage({ isSohoPortal: true, messageType, uuid, data });
      };

      switch (messageType) {
        case PortalMessageType.IsAvailableRequest:
          sendLdaPostMessage(PortalMessageType.IsAvailableResponse, true);
          break;

        case PortalMessageType.DesignerReadyRequest:
          console.info('Label Designer ready');
          break;

        case PortalMessageType.CloseDesignerRequest:
          const { lastUrl } = queryParams;
          if (lastUrl) {
            return history.push(decodeURIComponent(lastUrl));
          }
          history.goBack();
          history.goBack();
          break;

        case PortalMessageType.OpenUploadDataSourceDialogRequest: {
          console.debug(...DBG, 'uploadDataSource');
          const unsubscribe = window['store'].subscribe(() =>
            handleCallback(
              (data) => {
                if (data) {
                  sendLdaPostMessage(
                    PortalMessageType.OpenUploadDataSourceDialogResponse,
                    data,
                  );
                }
                unsubscribe();
              },
              'iframe',
              'dataSource',
            ),
          );

          getIframeDataSource({ type: 'Local' });
          break;
        }

        case PortalMessageType.OpenLinkDataSourceDialogRequest: {
          console.debug(...DBG, 'linkDataSource');
          const unsubscribe = window['store'].subscribe(() => {
            const { success, data } =
              window['store'].getState()['dataSources']['add'];
            if (success) {
              unsubscribe();
              getFileMetaData(data, uuid);
            }
          });

          getIframeDataSource({ type: 'Cloud' });
          break;
        }

        case PortalMessageType.OpenUploadImageDialogRequest: {
          console.debug(...DBG, 'uploadImage');
          const unsubscribe = window['store'].subscribe(() =>
            handleCallback(
              (data) => {
                if (data) {
                  sendLdaPostMessage(
                    PortalMessageType.OpenUploadImageDialogResponse,
                    data,
                  );
                }
                unsubscribe();
              },
              'iframe',
              'image',
            ),
          );

          getIframeImage({ type: 'Local' });

          // Safari fix -> Click on file input will be initiated in Safari, only in event started by user: https://stackoverflow.com/a/21583865
          const fileInput: HTMLElement | null =
            document.querySelector('[name="uploadImg"]');
          fileInput?.click();
          break;
        }

        case PortalMessageType.OpenLinkImageDialogRequest: {
          console.debug(...DBG, 'linkImage');
          const unsubscribe = window['store'].subscribe(() => {
            const { success, data } =
              window['store'].getState()['dataSources']['add'];
            if (success) {
              unsubscribe();
              getFileMetaData(data, uuid);
            }
          });

          getIframeImage({ type: 'Cloud' });
          break;
        }

        case PortalMessageType.OpenGalleryImageDialogRequest: {
          console.debug(...DBG, 'getGalleryImage');
          const unsubscribe = window['store'].subscribe(() =>
            handleCallback(
              (data) => {
                if (data) {
                  sendLdaPostMessage(
                    PortalMessageType.OpenGalleryImageDialogResponse,
                    data,
                  );
                }
                unsubscribe();
              },
              'iframe',
              'image',
            ),
          );

          getIframeImage({ type: 'Gallery' });
          break;
        }

        case PortalMessageType.OpenCameraDialogRequest: {
          console.debug(...DBG, 'captureImage');
          const unsubscribe = window['store'].subscribe(() =>
            handleCallback(
              (data) => {
                if (data) {
                  sendLdaPostMessage(
                    PortalMessageType.OpenCameraDialogResponse,
                    data,
                  );
                }
                unsubscribe();
              },
              'iframe',
              'image',
            ),
          );

          getIframeImage({ type: 'Camera' });
          break;
        }

        case PortalMessageType.OpenPrintOptionsDialogRequest: {
          console.debug(...DBG, 'openPrintOptions');
          const { id } = data;
          getSelectedTemplateData({ template: { id } });
          openPrintDialog(id);
          break;
        }

        case PortalMessageType.GetVersionRequest:
          sendLdaPostMessage(
            PortalMessageType.GetVersionResponse,
            APPLICATION_VERSION,
          );
          break;

        case PortalMessageType.GetAuthorizationRequest: {
          const { token } = tokenStorage.get();
          sendLdaPostMessage(
            PortalMessageType.GetAuthorizationResponse,
            `Bearer ${token}`,
          );
          break;
        }

        case PortalMessageType.GetClientIdRequest:
          sendLdaPostMessage(
            PortalMessageType.GetClientIdResponse,
            getClientId(),
          );
          break;

        case PortalMessageType.GetCustomerIdRequest:
          sendLdaPostMessage(
            PortalMessageType.GetCustomerIdResponse,
            customer?.id,
          );
          break;

        case PortalMessageType.GetPrinterInfosRequest: {
          const { workspaceId } = data;
          const { token } = tokenStorage.get();
          const printers = await getPrinters(customer?.id, workspaceId, token);
          sendLdaPostMessage(
            PortalMessageType.GetPrinterInfosResponse,
            printers,
          );
          break;
        }

        case PortalMessageType.GetCustomerInfoRequest:
          // Not implemented on LDA side
          break;

        case PortalMessageType.GetStockInfosRequest: {
          const { token } = tokenStorage.get();
          const labelStocks = await getAllLabelSizes(customer?.id, token);
          sendLdaPostMessage(
            PortalMessageType.GetStockInfosResponse,
            labelStocks,
          );
          break;
        }

        case PortalMessageType.GetDatabaseFileListRequest: {
          console.debug(...DBG, 'listDataSources');
          const unsubscribe = window['store'].subscribe(() =>
            handleFiles((data) => {
              const files = data.filter(
                (file) => DOWNLOAD_DB_MIMETYPES.indexOf(file.mimetype) > -1,
              );
              sendLdaPostMessage(
                PortalMessageType.GetDatabaseFileListResponse,
                files,
              );
              unsubscribe();
            }, 'all'),
          );

          getFileList();
          break;
        }

        case PortalMessageType.GetContactsSourcesRequest:
          console.debug(...DBG, 'listContactsSources');
          setTimeout(
            () =>
              sendLdaPostMessage(PortalMessageType.GetContactsSourcesResponse, [
                { provider: ContactsProvider.GOOGLE, count: 250 },
                { provider: ContactsProvider.OFFICE365, count: 120 },
                { provider: ContactsProvider.MOBILE_DEVICE, count: 120 },
              ]),
            250,
          );
          break;

        case PortalMessageType.GetImageFileListRequest: {
          console.debug(...DBG, 'listImages');
          const unsubscribe = window['store'].subscribe(() =>
            handleFiles((data) => {
              const images = data.filter(
                (file) => IMAGE_MIMETYPES.indexOf(file.mimetype) > -1,
              );
              sendLdaPostMessage(
                PortalMessageType.GetImageFileListResponse,
                images,
              );
              unsubscribe();
            }, 'all'),
          );

          getFileList();
          break;
        }

        case PortalMessageType.RequestContactsSourceMetaDataRequest: {
          const { provider } = data;

          switch (provider) {
            case ContactsProvider.GOOGLE:
              console.debug(
                ...DBG,
                'getContactsColumns Google',
                GOOGLE_CONTACTS_HEADERS,
              );
              googleApi
                .getContacts()
                .then((contacts: any[]) => {
                  sendLdaPostMessage(
                    PortalMessageType.RequestContactsSourceMetaDataResponse,
                    {
                      columns: contactsArrayToDataSourceObject(contacts),
                      provider: ContactsProvider.GOOGLE,
                    },
                  );
                })
                .catch((error: any) => {
                  if (error instanceof PermissionsNotGrantedError) {
                    dispatch(
                      openErrorToast(buildPermissionsNotGrantedToastOptions()),
                    );
                  } else {
                    dispatch(openErrorToast(error.message ?? error));
                  }
                });
              break;

            case ContactsProvider.OFFICE365:
              console.debug(...DBG, 'getContactsColumns MS', MS_CONTACT_FIELDS);
              getOneDriveContacts(
                msalContext,
                isOneDriveAuthenticated,
                true,
              ).then((contacts: any[]) => {
                sendLdaPostMessage(
                  PortalMessageType.RequestContactsSourceMetaDataResponse,
                  {
                    columns: contactsArrayToDataSourceObject(
                      contacts.slice(0, 2),
                    ),
                    provider: ContactsProvider.OFFICE365,
                  },
                );
              });
              break;

            case ContactsProvider.MOBILE_DEVICE:
              sendLdaPostMessage(
                PortalMessageType.RequestContactsSourceMetaDataResponse,
                {
                  columns: contactsArrayToDataSourceObject(
                    MOBILE_CONTACT_COLUMNS,
                  ),
                  provider: ContactsProvider.MOBILE_DEVICE,
                },
              );
              break;
          }
          break;
        }

        case PortalMessageType.RequestFileMetaDataRequest: {
          const { id, name, provider, mimetype } = data;

          switch (provider) {
            case ContactsProvider.GOOGLE:
              console.debug(
                ...DBG,
                'getContactsColumns Google',
                GOOGLE_CONTACTS_HEADERS,
              );
              googleApi
                .getContacts()
                .then((contacts: any[]) => {
                  const dataSourceObject =
                    contactsArrayToDataSourceObject(contacts);
                  sendLdaPostMessage(PortalMessageType.FileSelectedResponse, {
                    contacts: true,
                    provider: ContactsProvider.GOOGLE,
                    rowCount: dataSourceObject.length,
                    columns: dataSourceObject,
                  });
                })
                .catch((error: any) => {
                  if (error instanceof PermissionsNotGrantedError) {
                    dispatch(
                      openToast(buildPermissionsNotGrantedToastOptions()),
                    );
                  } else {
                    dispatch(openToast(error.message ?? error));
                  }
                });
              break;

            case ContactsProvider.OFFICE365:
              console.debug(...DBG, 'getContactsColumns MS', MS_CONTACT_FIELDS);
              getOneDriveContacts(
                msalContext,
                isOneDriveAuthenticated,
                true,
              ).then((contacts: any[]) => {
                const dataSourceObject =
                  contactsArrayToDataSourceObject(contacts);
                sendLdaPostMessage(PortalMessageType.FileSelectedResponse, {
                  contacts: true,
                  provider: ContactsProvider.OFFICE365,
                  rowCount: dataSourceObject.length,
                  columns: dataSourceObject,
                });
              });
              break;

            case ContactsProvider.MOBILE_DEVICE:
              const dataSourceObject = contactsArrayToDataSourceObject(
                MOBILE_CONTACT_COLUMNS,
              );
              sendLdaPostMessage(PortalMessageType.FileSelectedResponse, {
                contacts: true,
                provider: ContactsProvider.MOBILE_DEVICE,
                rowCount: dataSourceObject.length,
                columns: dataSourceObject,
              });
              break;
          }

          getFileMetaData({ id, name, provider, mimetype }, uuid);
          break;
        }

        case PortalMessageType.ShowToastRequest: {
          const { message, type, duration, details } = data;
          console.debug(...DBG, 'toast');
          dispatch(
            openToast({
              title: message,
              variant: type,
              dismissTime: duration,
              message: details,
            }),
          );
          break;
        }

        case PortalMessageType.DebugCompressImageRequest: {
          sendLdaPostMessage(
            PortalMessageType.DebugCompressImageResponse,
            await compressImage(data),
          );
          break;
        }

        case PortalMessageType.DebugGetPortalVersionRequest: {
          const { token } = tokenStorage.get();
          sendLdaPostMessage(
            PortalMessageType.DebugGetPortalVersionResponse,
            await getPortalVersion(customer?.id, token),
          );
          break;
        }

        case PortalMessageType.DebugGetWorkspacesRequest: {
          const { token } = tokenStorage.get();
          sendLdaPostMessage(
            PortalMessageType.DebugGetWorkspacesResponse,
            await getWorkspaces(customer?.id, token),
          );
          break;
        }
      }
    },
    [
      customer,
      history,
      dispatch,
      sendPostMessage,
      getFileList,
      getIframeDataSource,
      getIframeImage,
      getSelectedTemplateData,
      openPrintDialog,
      getFileMetaData,
      msalContext,
      isOneDriveAuthenticated,
      queryParams,
    ],
  );

  const handlePostMessageResponseInternal = useCallback(
    async (event: MessageEvent): Promise<void> => {
      if (!isLdaPostMessageValid(event)) {
        return;
      }

      const messageType = event.data?.messageType as PortalMessageType;
      const uuid = event.data?.uuid;
      const data = event.data?.data;

      await handlePostMessage(messageType, uuid, data);
    },
    [handlePostMessage],
  );

  const handleMessage = useCallback(
    async (event) => {
      const { data } = event;
      if (!data?.source?.startsWith('react')) {
        await handlePostMessageResponseInternal(event);
      }
    },
    [handlePostMessageResponseInternal],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return <>{children}</>;
};
