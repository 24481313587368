import { RootState } from 'store';
import { createSelector } from 'reselect';
import { FeedOnHeadClosePayload } from './actions';

export const allPrinters = (state: RootState) => state.printers.all.data;
const allPrintersLoading = (state: RootState) => state.printers.all.loading;
const isLoadingEdit = (state: RootState) => state.printers.update.loading;
const isEditSuccess = (state: RootState) => state.printers.update.success;
const isLoadingRemove = (state: RootState) => state.printers.remove.loading;
const isRemoveSuccess = (state: RootState) => state.printers.remove.success;
const isLoadingCancelAll = (state: RootState) =>
  state.printers.cancelAll.loading;
const isCancelAllSuccess = (state: RootState) =>
  state.printers.cancelAll.success;
const isLoadingReprint = (state: RootState) => state.printers.reprint.loading;
const isReprintSuccess = (state: RootState) => state.printers.reprint.success;
const selected = (state: RootState) => state.printers.selected.data;
export const fohcData = (state: RootState): FeedOnHeadClosePayload =>
  state.printers.fohc.data;

export const getAllPrinters = createSelector([allPrinters], (printers) =>
  printers ? printers : null,
);

export const getAllPrintersCount = createSelector([allPrinters], (printers) =>
  printers ? printers.length : 0,
);

export const getAllPrintersLoading = createSelector(
  [allPrintersLoading],
  (loading) => loading,
);

export const getIsLoadingEdit = createSelector(
  [isLoadingEdit],
  (loading) => loading,
);

export const getIsEditSuccess = createSelector(
  [isEditSuccess],
  (success) => success,
);

export const getIsRemoveSuccess = createSelector(
  [isRemoveSuccess],
  (success) => success,
);

export const getIsLoadingRemove = createSelector(
  [isLoadingRemove],
  (loading) => loading,
);

export const getIsCancelAllSuccess = createSelector(
  [isCancelAllSuccess],
  (success) => success,
);

export const getIsLoadingCancelAll = createSelector(
  [isLoadingCancelAll],
  (loading) => loading,
);

export const getIsReprintSuccess = createSelector(
  [isReprintSuccess],
  (success) => success,
);

export const getIsLoadingReprint = createSelector(
  [isLoadingReprint],
  (loading) => loading,
);

export const getSelectedPrinter = createSelector(
  [selected, getAllPrinters],
  (selected, allPrinters) => {
    const printer = allPrinters?.find((p: any) => p.uniqueId === selected);
    return printer ? printer : {};
  },
);

export const selectAllPrinters = createSelector([allPrinters], (printers) =>
  printers ? printers : [],
);

export const selectIsLoadingPrinters = createSelector(
  [(state: any) => state.printers.all],
  (printers) => printers.loading || !printers.hadReturn,
);

export const selectAllPrintersOnlineFirst = createSelector(
  [selectAllPrinters],
  (printers) =>
    [...printers].sort(
      (a, b) => Number(b.status.isOnline) - Number(a.status.isOnline),
    ),
);
