import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Box, Flex, ToggleSwitch } from 'components';
import { useModal, useReduxSelector, useReduxAction } from 'hooks';
import { fohcData, getSelectedPrinter } from 'ducks/printers/selectors';
import { useTranslation } from 'react-i18next';
import {
  actions as printersActions,
  FeedOnHeadClosePayload,
} from 'ducks/printers/actions';
import { StyledInfoIcon } from './FohcSetting.styles';
import FohcInfoModal from './FohcInfoModal';
import { PrinterSettings } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import {
  convertHeadClosedActionToString,
  isFeedOnHeadCloseEnabled,
} from 'utils/feedOnHeadClose';

const FohcSetting = () => {
  const { t } = useTranslation(['common', 'overview']);

  const printer = useReduxSelector(getSelectedPrinter);
  const uniqueId = printer.uniqueId;
  const fohcPayload: FeedOnHeadClosePayload = useReduxSelector(fohcData);
  const headClosedAction = fohcPayload?.[uniqueId]?.headClosedAction;

  const setPrinterSettings = useReduxAction(
    printersActions.SET_SETTINGS.request,
  );
  const refreshFohc = useReduxAction(
    printersActions.FEED_ON_HEAD_CLOSE.request,
  );

  const infoModalRef = useRef(null);
  const infoModalHook = useModal(infoModalRef);

  const [allowDisableFohc, setAllowDisableFohc] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(fohcPayload?.[uniqueId]?.loading !== false);
  }, [fohcPayload, uniqueId]);

  useEffect(() => {
    if (uniqueId) {
      refreshFohc({ uniqueId });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [uniqueId]);

  const updatePrinterFeedOnHeadClose = useCallback(
    (isEnabled: boolean) => {
      const printerSettings: PrinterSettings = new PrinterSettings();
      const headClosedAction = isEnabled
        ? PrinterSettings.HeadClosedAction.FEED
        : PrinterSettings.HeadClosedAction.NO_MOTION;
      printerSettings.setHeadclosedaction(headClosedAction);
      printerSettings.setPrinteruniqueid(uniqueId);
      setPrinterSettings({ printerSettings });
    },
    [setPrinterSettings, uniqueId],
  );

  const handleInfoButtonClick = useCallback(() => {
    setAllowDisableFohc(false);
    infoModalHook.open?.();
  }, [infoModalHook]);

  const handleFeedOnHeadCloseSwitch = useCallback(
    (isEnabled: boolean) => {
      if (!isEnabled) {
        setAllowDisableFohc(true);
        infoModalHook.open?.();
        return;
      }

      updatePrinterFeedOnHeadClose(isEnabled);
    },
    [updatePrinterFeedOnHeadClose, infoModalHook],
  );

  return (
    <>
      <Box mt={25}>
        <Flex width="100%" flexDirection={'column'}>
          <ToggleSwitch
            data-testid="feed-on-head-close-switch"
            disabled={!printer?.status?.isOnline || isLoading}
            value={convertHeadClosedActionToString(headClosedAction)}
            label={t('overview:printer.feed-on-head-close')}
            defaultChecked={isFeedOnHeadCloseEnabled(headClosedAction)}
            isLoading={isLoading}
            onChange={(e) => {
              handleFeedOnHeadCloseSwitch(e.target.checked);
            }}
            greyLabel={true}
            componentNextToText={
              <StyledInfoIcon
                data-testid="fohc-setting-info-button"
                ml={'8px'}
                mb={'-6px'}
                onClick={handleInfoButtonClick}
              />
            }
          />
        </Flex>
      </Box>
      <FohcInfoModal
        modalRef={infoModalRef}
        showDisableButton={allowDisableFohc}
        onDisableClick={() => {
          updatePrinterFeedOnHeadClose(false);
        }}
      />
    </>
  );
};

export default FohcSetting;
