import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Input, Slider } from 'components';
import { ErrorText } from 'v2/components/common';
import { useReduxAction, useReduxSelector } from 'hooks';
import { getSelectedPrinter } from 'ducks/printers/selectors';
import { selectIsTestPrintLoading } from 'ducks/templates/selectors';
import { actions as printersActions } from 'ducks/printers/actions';
import { actions as templatesActions } from 'ducks/templates/actions';
import useDebounce from 'hooks/useDebounce';
import { PRINTER_NAME_LENGTH_MAX } from 'utils/config';
import FohcSetting from './fohc/FohcSetting';
import {
  selectPrinterSettingsTabChange,
  setPrinterSettingsTabChange,
} from 'state/GlobalUtils';
import {
  Container,
  SettingsColumn,
  ContentText,
  ExampleText,
  PreviewTitle,
  PreviewWrapper,
  ContentWrapper,
  SectionTitle,
  Title,
} from './General.styles';

export const General = () => {
  const { t } = useTranslation([
    'settings',
    'common',
    'overview',
    'notifications',
  ]);
  const printer = useReduxSelector(getSelectedPrinter);
  const isTabChange = useReduxSelector(selectPrinterSettingsTabChange);
  const setTabChange = useReduxAction(setPrinterSettingsTabChange);
  const isTestPrintLoading = useReduxSelector(selectIsTestPrintLoading);

  const selectedPrinter = useReduxSelector(getSelectedPrinter);

  const [printerName, setPrinterName] = useState<string>(printer.name);
  const [darknessLevel, setDarknessLevel] = useState<number>(
    +printer.darkness + 7,
  );
  const [oldDarknessLevel, setOldDarknessLevel] = useState<number>(
    +printer.darkness,
  );

  const editPrinter = useReduxAction(printersActions.UPDATE.request);
  const printTestLabel = useReduxAction(templatesActions.TEST_PRINT.request);

  const debounceName = useDebounce(printerName, 1000);
  const debounceDarkness = useDebounce(darknessLevel, 1000);

  useEffect(() => {
    if (isNaN(darknessLevel)) {
      setDarknessLevel(7);
      setOldDarknessLevel(0);
    } else {
      const newDarkness: number = +darknessLevel - 7;
      if (
        (printer.name !== printerName && printerName !== '') ||
        oldDarknessLevel !== newDarkness
      ) {
        setOldDarknessLevel(newDarkness);
        if (!isTabChange && printerName.length <= PRINTER_NAME_LENGTH_MAX) {
          editPrinter({
            ...printer,
            name: printerName.trim() || printer.name.trim(),
            darkness: newDarkness,
          });
        }
      }
      if (isTabChange) {
        setTabChange(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceName, debounceDarkness]);

  /*This is used to handle switching the tabChange to false in order to prevent
   not being able to change printer settings on first load of some of the tabs*/
  useEffect(() => {
    setTabChange(false);
  }, [setTabChange]);

  useEffect(() => {
    setPrinterName(printer.name);
    setDarknessLevel((printer.darkness ?? 0) + 7);
  }, [printer]);

  const changePrinterNameHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPrinterName(event.target.value);
    },
    [],
  );

  const printerNameOnBlurHandler = useCallback(() => {
    if (printerName === '') {
      setPrinterName(printer.name);
    }
  }, [printer.name, printerName]);

  return (
    <Container flexWrap="wrap" mt={9} justifyContent="flex-start">
      <SettingsColumn>
        <ContentWrapper>
          <Title>{t('settings:printer.name')}</Title>
          <ContentText>
            {t('settings:printer.printer-name-explained')}
          </ContentText>
          <SectionTitle>{t('settings:printer.name')}</SectionTitle>
          <Input
            data-testid="input-printer-name"
            value={printerName}
            onChange={changePrinterNameHandler}
            onBlur={printerNameOnBlurHandler}
            padding-right={183}
            hasError={printerName.length > PRINTER_NAME_LENGTH_MAX}
          />

          {printerName.length > PRINTER_NAME_LENGTH_MAX && (
            <ErrorText variant="label">
              {t('settings:printer-name-can-not-exceed-length')}
            </ErrorText>
          )}

          <Box mt={40} mb={40}>
            <Title>{t('settings:printer.auto-label-feed')}</Title>
            <ContentText>
              {t('settings:printer.auto-label-feed-explained')}
            </ContentText>
            <Box width={'100%'}>
              <FohcSetting />
            </Box>
          </Box>
          <Title>{t('settings:printer.print-darkness')}</Title>
          <ContentText>
            {t('settings:printer.print-darkness-explained')}
          </ContentText>
          <SectionTitle>{t('settings:printer.darkness-level')}</SectionTitle>
          <Flex alignItems={'flex-end'} mt={4}>
            <Slider
              value={darknessLevel}
              min={0}
              max={14}
              onChange={(v) => setDarknessLevel(v)}
              labelStart={t('settings:printer.print-lightest')}
              labelEnd={t('settings:printer.print-darkest')}
              disabled={printer.status.isHeadOpen || printer.status.isPaperOut}
            />
          </Flex>
          <Flex
            mt={23}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <PreviewTitle>{t('common:preview')}</PreviewTitle>
            <PreviewWrapper>
              <ExampleText
                darkness={
                  typeof darknessLevel !== 'number'
                    ? (parseInt(darknessLevel) + 1) * (1 / 16)
                    : (darknessLevel + 1) * (1 / 16)
                }
              >
                {t('overview:printer.a4e')}
              </ExampleText>
            </PreviewWrapper>
            <Button
              data-testid="print-test-label-button"
              disabled={!printer?.status?.isOnline || isTestPrintLoading}
              onClick={() => {
                printTestLabel({
                  printer: selectedPrinter,
                  fields: [],
                  copies: 1,
                });
              }}
              style={{ flex: 1, justifyContent: 'center' }}
            >
              {t('overview:printer.print-test-label')}
            </Button>
          </Flex>
        </ContentWrapper>
      </SettingsColumn>
    </Container>
  );
};
