import { addDays } from 'date-fns';
import { put, takeLatest, select, take, fork } from 'redux-saga/effects';
import {
  getLinks,
  getNicelabelVersions,
  getPrinterTools,
  sendWhoAmI,
  getClientId,
} from 'services/api';
import tokenStorage from 'services/tokenStorage';
import { types as infoTypes, actions as infoActions } from 'ducks/info/actions';
import { getDithering, getUnit } from 'ducks/preferences/selectors';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { apiCall } from './utils';
import { driverDownloadClicked } from 'state/UI';
import { downloadFile } from 'utils/files';

function* handleLinksRequest() {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();

    const links = yield apiCall(getLinks, customer?.id, token);

    yield put(infoActions.LINKS.success(links));
  } catch (err: any) {
    console.error('LINKS', err);
    yield put(infoActions.LINKS.failure({ error: err }));
  }
}

function* handleLDAVersionsRequest() {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();

    const links = yield apiCall(getNicelabelVersions, customer?.id, token);

    yield put(infoActions.LDA_VERSIONS.success(links));
  } catch (err: any) {
    console.error('LDA_VERSIONS', err);
    yield put(infoActions.LDA_VERSIONS.failure({ error: err }));
  }
}

function* handlePrinterToolsRequest() {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();

    let dithering = yield select(getDithering);
    dithering = dithering ?? 0;
    let unit = yield select(getUnit);
    unit = unit ? unit.name : '';

    const printerToolsWindows = yield apiCall(
      getPrinterTools,
      unit,
      dithering,
      'Windows',
      customer?.id,
      token,
    );
    const printerToolsMac = yield apiCall(
      getPrinterTools,
      unit,
      dithering,
      'Mac',
      customer?.id,
      token,
    );

    yield put(
      infoActions.PRINTER_TOOLS.success({
        windows: printerToolsWindows,
        mac: printerToolsMac,
      }),
    );
  } catch (err: any) {
    console.error('PRINTER_TOOLS', err);
    yield put(infoActions.PRINTER_TOOLS.failure({ error: err }));
  }
}

function* handleWhoAmIRequest() {
  const clientId = getClientId();

  try {
    const lastRequest = JSON.parse(localStorage.getItem('whoAmI') || '');

    if (lastRequest.clientId !== clientId) {
      throw new Error('ClientId is not matching.');
    }

    if (new Date().getTime() > addDays(lastRequest.time, 30).getTime()) {
      throw new Error('WhoAmI should be sent only once per 30 days.');
    }

    // Don't send WhoAmI request
    return;
  } catch (e) {
    console.debug(e);
  }

  try {
    const dithering = yield select(getDithering);
    const unit = yield select(getUnit);
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    yield apiCall(
      sendWhoAmI,
      unit?.abbreviation,
      dithering,
      customer?.id,
      token,
      clientId,
    );

    localStorage.setItem(
      'whoAmI',
      JSON.stringify({ clientId, time: new Date().getTime() }),
    );

    yield put(infoActions.WHO_AM_I.success({}));
  } catch (err: any) {
    console.error('WHO_AM_I', err);
    yield put(infoActions.WHO_AM_I.failure({ error: err }));
  }
}

function* downloadDrivers({
  payload,
}: ReturnType<typeof driverDownloadClicked>) {
  yield fork(handlePrinterToolsRequest);

  const action = yield take(infoTypes.PRINTER_TOOLS.SUCCESS);
  const driver = action.payload[payload.os.toLowerCase()];

  downloadFile(driver.url);
}

export default function* watcher() {
  yield takeLatest(infoTypes.PRINTER_TOOLS.REQUEST, handlePrinterToolsRequest);
  yield takeLatest(infoTypes.LINKS.REQUEST, handleLinksRequest);
  yield takeLatest(infoTypes.LDA_VERSIONS.REQUEST, handleLDAVersionsRequest);
  yield takeLatest(infoTypes.WHO_AM_I.REQUEST, handleWhoAmIRequest);
  yield takeLatest(driverDownloadClicked.type, downloadDrivers);
}
