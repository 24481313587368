import React from 'react';
import { Box, Modal } from 'components';
import { getAssetPath } from '../../../../utils/files';

const FohcVideoModal = ({ modalRef }) => {
  return (
    <Modal
      data-testid="fohc-video-modal"
      ref={modalRef}
      defaultPadding={false}
      headerPadding={'10px'}
      closeIconRight={'10px'}
      closeIconTop={'2px'}
    >
      <Box p={'0px 20px 20px 20px'}>
        <video style={{ height: '100%', width: '100%' }} autoPlay={true} muted={true} controls>
          <source src={getAssetPath('/pull-media-to-end.mp4')} type="video/mp4" />
        </video>
      </Box>
    </Modal>
  );
}

export default FohcVideoModal;
