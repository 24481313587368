import { put, takeLatest } from 'redux-saga/effects';
import { getAnnouncements } from 'services/api';
import tokenStorage from 'services/tokenStorage';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import {
  clearBannerMessages,
  fetchBannerMessages,
  setBannerMessages,
} from '../state/Announcement';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { apiCall } from './utils';

function* handleFetchBannerMessages() {
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const bannerMessages: BannerMessage[] = yield apiCall(
      getAnnouncements,
      customer?.id,
      token,
    );

    yield put(
      setBannerMessages({
        bannerMessages,
        customer,
      }),
    );
  } catch (error) {
    yield put(clearBannerMessages());
  }
}

export default function* watcher() {
  yield takeLatest(fetchBannerMessages, handleFetchBannerMessages);
}
