import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useModal, useReduxAction, useReduxSelector } from 'hooks';
import { Flex } from 'components';
import {
  getSelectedFile,
  isAddingFileSuccess,
} from 'ducks/dataSources/selectors';
import { getQuery } from 'ducks/router/selectors';
import { getAllPrinters } from 'ducks/printers/selectors';
import { getCurrentWorkspace } from 'ducks/workspaces/selectors';
import i18n from 'i18next';
import { LDA_URL } from 'utils/config';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';
import { actions as iframeActions } from 'ducks/iframe/actions';
import { getDataSourceLoading, getImageLoading } from 'ducks/iframe/selectors';
import CloudModal from '../dataSources/components/Cloud/CloudModal';
import CameraModal from '../dataSources/components/Camera/CameraModal';
import GalleryModal from '../dataSources/components/Gallery/GalleryModal';
import { supportedDbTypes, supportedImageTypes } from 'services/api';
import { actions as SideBar } from 'ducks/sideBar/actions';
import { useMediaQuery } from 'react-responsive';
import { getCustomer, getUser } from 'ducks/auth/selectors';
import { getUnit } from 'ducks/preferences/selectors';
import { INCH } from 'utils/unitFormatter';
import { device } from '../../utils/mediaQueries';
import { useTranslation } from 'react-i18next';
import { IMsalContext, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useLabelRange } from '../../components/app/print-dialog/LabelRangeContext';
import OverwriteFileModal from '../dataSources/components/OverwriteFileModal';
import { isOverwriteFileModalOpened } from '../../ducks/modalDialog/selectors';
import { LDAListener } from '../../components/common/LDAListener';
import { PortalMessageType } from '../../components/lda/PortalMessageType';
import { v4 as uuidv4 } from 'uuid';
import { FileProviderFactory } from '../../utils/file/FileProviderFactory';
import { getLdaPostMessageTargetOrigin, LdaPostMessage } from '../../utils/lda';

let timerId;

export const Designer = () => {
  const { t } = useTranslation('other');
  const msalContext = useMsal();
  const isOneDriveAuthenticated = useIsAuthenticated();
  const [
    { labelRange, error: labelRangeError, success: labelRangeSuccess },
    { setFile, reset: resetLabelRange },
  ] = useLabelRange();

  const [designerUrl, setDesignerUrl] = useState('about:blank');

  const designerRef = useRef<HTMLIFrameElement>(null);
  const fileSelected = useReduxSelector(getSelectedFile);
  const userObj = useReduxSelector(getUser);
  const queryParams = useReduxSelector(getQuery);
  const currentWorkspace = useReduxSelector(getCurrentWorkspace);
  const printers = useReduxSelector(getAllPrinters);
  const customer = useReduxSelector(getCustomer);
  const overwriteFileModalIsOpened = useReduxSelector(
    isOverwriteFileModalOpened,
  );

  const cloudModalRef = useRef(null);
  const cloudModalHook = useModal(cloudModalRef);
  const cameraModalRef = useRef(null);
  const cameraModalHook = useModal(cameraModalRef);
  const galleryModalRef = useRef(null);
  const galleryModalHook = useModal(galleryModalRef);
  const overwriteFileModalRef = useRef(null);

  const isImageRequested = useReduxSelector(getImageLoading);
  const isDataSourceRequested = useReduxSelector(getDataSourceLoading);
  const clearImageRequest = useReduxAction(iframeActions.IMAGE.clear);
  const clearDataSourceRequest = useReduxAction(iframeActions.DATASOURCE.clear);
  const imageSuccess = useReduxAction(iframeActions.IMAGE.success);
  const imageFailure = useReduxAction(iframeActions.IMAGE.failure);
  const dbRef = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLInputElement>(null);
  const addFileAction = useReduxAction(dataSourcesActions.ADD.request);

  const [uploading, setUploading] = useState(false);

  const units = useReduxSelector(getUnit);
  const setReduxCollapsed = useReduxAction(SideBar.COLLAPSED);

  const fileAdded = useReduxSelector(isAddingFileSuccess);

  const sendPostMessage = useCallback(
    (postMessage: LdaPostMessage): void => {
      designerRef.current?.contentWindow?.postMessage(
        postMessage,
        getLdaPostMessageTargetOrigin(),
      );
    },
    [designerRef],
  );

  const getDimensions = useCallback((fileType, data) => {
    return new Promise(function (resolved, rejected) {
      const i = new Image();
      i.onload = function () {
        resolved({ width: i.width, height: i.height });
      };
      i.onerror = function () {
        rejected();
      };
      i.src = `data:${fileType};base64,${data}`;
    });
  }, []);

  const sendToIframe = useCallback(
    async (file, iframe, data) => {
      let dimensions;
      if (file.fileType.startsWith('image')) {
        dimensions =
          file.dimensions ?? (await getDimensions(file.fileType, data));
      }

      sendPostMessage({
        isSohoPortal: true,
        messageType: PortalMessageType.FileSelectedResponse,
        uuid: uuidv4(),
        data: {
          ...file,
          dimensions,
          data: data,
        },
      });
    },
    [getDimensions, sendPostMessage],
  );

  const sendFile = useCallback(
    async (
      file,
      iframe,
      customerId,
      workspaceId,
      msalContext?: IMsalContext,
      isOneDriveAuthenticated?: boolean,
    ) => {
      const fileProvider = FileProviderFactory.getInstance(file);
      if (fileProvider) {
        const content = await fileProvider.getFileData(file, {
          customerId,
          workspaceId,
          msalContext,
          isOneDriveAuthenticated,
        });

        await sendToIframe(file, iframe, content);
      }
    },
    [sendToIframe],
  );

  useEffect(() => {
    const _setDesignerUrl = () => {
      let queryString = Object.keys(queryParams)
        .filter((key) => key !== 'lastUrl')
        .map((key) => key + '=' + queryParams[key])
        .join('&');
      const urlParams = new URLSearchParams();
      urlParams.append('portalType', 'postMessage');
      urlParams.append('lang', i18n.language);
      urlParams.append(
        'printerId',
        printers && printers.length ? printers[0]?.uniqueId : '',
      );
      urlParams.append('theme', currentWorkspace?.theme);
      urlParams.append('workspaceId', currentWorkspace.id);
      urlParams.append(
        'units',
        units ? (units === INCH ? 'Inch' : units?.name) : 'Inch',
      );
      userObj && urlParams.append('userCountry', userObj?.country);

      const url = `${LDA_URL}?${queryString}&${urlParams}`;

      setDesignerUrl(url);
    };

    //NL LDA doesnt like having its URL changed lots of times.
    if (
      currentWorkspace.id !== undefined &&
      i18n.language !== undefined &&
      queryParams
    ) {
      if (timerId) clearTimeout(timerId);
      if (!printers) {
        timerId = setTimeout(_setDesignerUrl, 500);
      } else {
        _setDesignerUrl();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, printers, currentWorkspace]);

  useEffect(() => {
    return () => {
      setReduxCollapsed(false);
      resetLabelRange();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const iframe: any = designerRef?.current;
    if (iframe && fileAdded && !uploading) {
      //Maybe we should sniff the route instead?
      // selectFileAction(fileAdded);
    }
    if (uploading) setUploading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designerRef, fileAdded]);

  useEffect(() => {
    const { loading, type } = isImageRequested;
    if (loading === true && cloudModalHook) {
      if (type === 'Local') {
        openImageInput();
        setUploading(true);
      } else if (type === 'Cloud') {
        cloudModalHook.open();
        clearImageRequest();
      } else if (type === 'Gallery') {
        galleryModalHook.open();
      } else if (type === 'Camera') {
        cameraModalHook.open();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImageRequested]);

  useEffect(() => {
    const { loading, type } = isDataSourceRequested;
    if (loading === true && cloudModalHook) {
      if (type === 'Local') {
        openDbInput();
      } else if (type === 'Cloud') {
        cloudModalHook.open();
        clearDataSourceRequest();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataSourceRequested]);

  useEffect(() => {
    return () => {
      clearDataSourceRequest();
      clearImageRequest();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openImageInput = useCallback(() => {
    imgRef.current?.click();
  }, [imgRef]);

  const openDbInput = useCallback(() => {
    dbRef.current?.click();
  }, [dbRef]);

  const handleImageInput = useCallback(
    (e) => {
      if (imgRef?.current?.files?.[0]) {
        addFileAction({
          file: imgRef?.current?.files?.[0],
          source: 'Local File',
          from: 'LDA',
          fileType: 'image',
        });
        e.target.value = '';
      } else {
        setUploading(false);
        imageFailure({});
      }
    },
    [addFileAction, imageFailure],
  );

  const handleCameraImage = (image, current) => {
    if (current && image) {
      const file = {
        data: image,
        dimensions: {
          width: current?.canvas?.width,
          height: current?.canvas?.height,
        },
        fileType: 'image/jpeg',
        fileName: 'Camera',
      };
      imageSuccess(file);
    } else if (image === '') {
      imageFailure({});
    }
  };

  const handleGalleryImage = (image, current) => {
    if (current && image) {
      const file = {
        data: image.base64,
        dimensions: {
          width: 512,
          height: 512,
        },
        fileType: 'image/png',
        fileName: image.name,
      };
      imageSuccess(file);
    } else if (image === '') {
      imageFailure({});
    }
  };

  const handleDbInput = useCallback(
    (e) => {
      if (dbRef?.current?.files?.[0]) {
        addFileAction({
          file: dbRef?.current?.files?.[0],
          source: 'Local File',
          from: 'LDA',
          fileType: 'dataSource',
        });
        e.target.value = '';
      } else {
        imageFailure({});
        setUploading(false);
      }
    },
    [addFileAction, imageFailure],
  );

  useEffect(() => {
    const iframe: any = designerRef?.current;
    if (fileSelected?.fileType?.startsWith('image')) {
      sendFile(
        fileSelected,
        iframe,
        customer?.id,
        currentWorkspace.id,
        msalContext,
        isOneDriveAuthenticated,
      );
    } else if (fileSelected?.fileType) {
      setFile(fileSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    designerRef,
    fileSelected,
    customer,
    currentWorkspace,
    msalContext.accounts,
    msalContext.instance,
    isOneDriveAuthenticated,
  ]);

  useEffect(() => {
    const iframe: any = designerRef?.current;
    let file;

    if (labelRangeSuccess) {
      let columns =
        labelRange?.[0]?.map((element, index) => ({
          value: index,
          label: element,
        })) || [];
      labelRange?.[1]?.forEach((element, index) => {
        columns[index].provisionalValue = element;
      });

      file = {
        ...fileSelected,
        columns,
        rowCount: labelRange.length,
      };
    } else if (labelRangeError) {
      file = fileSelected;
    }

    if (file) {
      sendFile(
        file,
        iframe,
        customer?.id,
        currentWorkspace.id,
        msalContext,
        isOneDriveAuthenticated,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    labelRange,
    labelRangeSuccess,
    labelRangeError,
    customer,
    currentWorkspace,
    msalContext.accounts,
    msalContext.instance,
    isOneDriveAuthenticated,
  ]);
  const isTablet = useMediaQuery({ query: device.tablet });

  return (
    <LDAListener sendPostMessage={sendPostMessage}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={isTablet ? 'calc(100vh - 71px)' : '100vh'}
        mt={isTablet ? '71px' : 0}
      >
        <iframe
          title={t('other:designer')}
          ref={designerRef}
          src={designerUrl}
          style={{ width: '100%', height: '100vh', border: 'none' }}
          width="100%"
          height="100vh"
        />
        <CloudModal ref={cloudModalRef} />
        <CameraModal ref={cameraModalRef} resolve={handleCameraImage} />

        <GalleryModal resolve={handleGalleryImage} ref={galleryModalRef} />
        {overwriteFileModalIsOpened && (
          <OverwriteFileModal ref={overwriteFileModalRef} />
        )}
        <input
          name="uploadDb"
          type="file"
          hidden
          accept={supportedDbTypes.toString()}
          ref={dbRef}
          onChange={handleDbInput}
          data-testid="localFiles-uploadDb"
        />
        <input
          name="uploadImg"
          type="file"
          hidden
          accept={supportedImageTypes.toString()}
          ref={imgRef}
          onChange={handleImageInput}
          data-testid="localFiles-uploadImg"
        />
      </Flex>
    </LDAListener>
  );
};
